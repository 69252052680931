import axiosData from "@/axios/axios.js";
const { axios, url, getHeaders } = axiosData;

export default {
  login({ signature, xPublicKey }) {
    return axios.post(`${url.user_ctrl}/metamask/login`, {
      signature, xPublicKey
    });
  },
  refresh({ signature }) {
    return axios.post(`${url.user_ctrl}/metamask/refresh`, {
      signature
    });
  },

  verifyMetaFileCoinDomain(newUsername, accessId) {
    return axios.post(
      `${url.user_ctrl}/metamask/lfi-username`,
      { newUsername, accessId },
    );
  },
  createMetaMaskUser(accessId, metaEncryptedKey) {
    return axios.post(
      `${url.user_ctrl}/metamask/create`,
      { accessId, metaEncryptedKey },
    );
  },
  VerifyPaymentAndCreateMetaMaskUser(accessId, metaEncryptedKey, paymentHash) {
    return axios.post(
      `${url.user_ctrl}/metamask/verify-payment`,
      { accessId, metaEncryptedKey, paymentHash },
    );
  },
  metaMaskChangeUserPassword(signature, accessKey, details) {
    return axios.post(
      `${url.user_ctrl}/metamask/auth/update-password`,
      { signature, accessKey, details },
      { headers: getHeaders() }
    );
  },
  metaMaskConnectWallet(signature, metaEncryptedKey) {
    return axios.post(
      `${url.user_ctrl}/metamask/auth/connect-wallet`,
      { signature, metaEncryptedKey },
      { headers: getHeaders() }
    );
  },
};
