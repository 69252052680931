const state = {
};

const mutations = {

};

const getters = {
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
