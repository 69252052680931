import ComposeAPI from "@/axios/api/compose-api";
import ledgermailV2 from "@/plugins/ledgermail-jslib";
import router from "@/router";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  VERIFY_MAIL: ({ getters, commit, dispatch }, emailIds) => {
    emailIds.push(getters.userInfo.emailId);
    emailIds = Array.from(new Set(emailIds));
    emailIds = emailIds.map((v) => v.trim().toLowerCase())

    return new Promise((resolve, reject) => {
      ComposeAPI.verifyMail(emailIds)
        .then(async ({ data }) => {
          if (data.error && data.balance)
            commit("AUTH_SET_BALANCE", data.balance);

          if (data.logout) {

            commit("SET_PARTIAL_LOADING_STATUS", true);
            if (await dispatch("REFRESH_TOKEN_WITH_PASSWORD")) {
              commit("SET_PARTIAL_LOADING_STATUS", false);
              return resolve({ retry: true });
            } else {
              dispatch("AUTH_LOGOUT").then(() => {
                router.push("/auth/login");
              });
            }
          }

          if (data.error && data.nonExistEmail && data.nonExistEmail) return resolve({ error: true, nonExistEmail: data.nonExistEmail })
          if (data.error) return reject(data.message);

          const DecryptEmails = ledgermailV2
            .ASEDecrypt(data.emails, `${getters.userInfo.emailId}ko#`)
            .split(",");

          // eslint-disable-next-line require-atomic-updates
          data.emails = DecryptEmails;
          // eslint-disable-next-line require-atomic-updates
          const decryptedUserData = JSON.parse(
            ledgermailV2.ASEDecrypt(
              data.userData,
              `${getters.userInfo.emailId}oo#`
            )
          );

          let checkList = Object.keys(decryptedUserData)
          for (let index = 0; index < Object.keys(decryptedUserData).length; index++) {
            const tempEmailId = checkList[index];

            if (decryptedUserData[tempEmailId].domainName && decryptedUserData[tempEmailId].domainName.length)
              checkList = [...checkList, ...decryptedUserData[tempEmailId].domainName]
          }

          const nonExistEmail = emailIds.filter(x => !checkList.includes(x))
          if (nonExistEmail && nonExistEmail.length) return resolve({ error: true, nonExistEmail });

          const domainHolders = {};
          for (let index = 0; index < emailIds.length; index++) {
            const tempDomainName = emailIds[index];
            let tempEmail = "";
            for (
              let emailIndex = 0;
              emailIndex < data.emails.length;
              emailIndex++
            ) {
              if (
                decryptedUserData[data.emails[emailIndex]].domainName.includes(
                  tempDomainName
                )
              ) {
                tempEmail = data.emails[emailIndex];
                if (domainHolders[tempEmail])
                  domainHolders[tempEmail].push(tempDomainName);
                domainHolders[tempEmail] = [tempDomainName];
                break;
              }
            }
            if (
              !data.emails.includes(tempDomainName) &&
              (domainHolders[tempEmail] && !domainHolders[tempEmail].includes(tempDomainName))
            ) {
              return resolve({ error: data });
            }
          }

          // eslint-disable-next-line require-atomic-updates
          data.domainHolders = domainHolders;

          return resolve(data);

        })
        .catch((error) => {
          if (error.response && error.response.data)
            reject(error.response.data);
          else if (error.message) reject(error.message);
          else reject("Server not reachable, Please try again after sometime");
        });
    });
  },

  SEND_NEW_MAIL: async ({ getters }, { body, token, publicKey }) => {
    body.sender = getters.userInfo.emailId;
    const enBody = await ledgermailV2.ASEEncrypt(
      JSON.stringify(body.body),
      body.rs
    );

    const param = { ...body, body: enBody };
    const data = await ledgermailV2.encrypt(JSON.stringify(param), publicKey);

    return await ComposeAPI.sendNewMail({ data, publicKey }, token);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
