const state = {
  alerts: [],
  snackbar: false,
  snackMessage: "",
  snackType: "info",
  mailSearch: "",
  inboxSearch: "",
  sentSearch: "",
  archiveSearch: "",
  pendingSearch: "",
  timer: false,
  alertTimer: {},
};

const mutations = {
  NEW_ALERT: (state, data) => {
    state.alerts.push(data);
    state.alertTimer[state.alerts.length] = setTimeout(() => {
      state.alerts.pop();
    }, 5000);
  },
  CLEAR_WITH_INDEX: (state, index) => {
    clearTimeout(state.alertTimer[index + 1]);
  },

  NEW_SNACK: (state, data) => {
    state.snackbar = true;
    state.snackMessage = data.message;
    state.snackType = data.type;
  },
  SNACKBAR_COMPLETE: (state, value) => {
    state.snackbar = value;
    state.snackMessage = "";
    state.snackType = "info";
  },
  SEARCH_IN_MAIL: (state, { search, page }) => {
    if (page === 0 && state.inboxSearch !== search) state.inboxSearch = search;
    else if (page === 1 && state.sentSearch !== search)
      state.sentSearch = search;
    else if (page === 2 && state.archiveSearch !== search)
      state.archiveSearch = search;
    else if (page === 3 && state.pendingSearch !== search)
      state.pendingSearch = search;
    else {
      state.inboxSearch =
        state.sentSearch =
        state.archiveSearch =
        state.pendingSearch =
        search;
    }
  },
};

const getters = {
  getAlerts: (state) => state.alerts,
  snackbar: (state) => state.snackbar,
  snackMessage: (state) => state.snackMessage,
  snackType: (state) => state.snackType,
  mailSearch: (state) => state.mailSearch,
  inboxSearch: (state) => state.inboxSearch,
  sentSearch: (state) => state.sentSearch,
  archiveSearch: (state) => state.archiveSearch,
  pendingSearch: (state) => state.pendingSearch,

  timer: (state) => state.timer,
};

const actions = {
  SNACKBAR: ({ commit }, message) => {
    commit("SNACKBAR_COMPLETE", false);
    commit("NEW_SNACK", { message, type: "info" });
  },
  ALERT_SUCCESS: ({ commit }, message) => {
    commit("NEW_ALERT", { message, type: "success" });
  },
  ALERT_ERROR: ({ commit }, message) => {
    commit("NEW_ALERT", { message, type: "error" });
  },
  ALERT_WARNING: ({ commit }, message) => {
    commit("NEW_ALERT", { message, type: "warning" });
  },

  CLEAR_ALERT: ({ commit }, index) => {
    commit("CLEAR_WITH_INDEX", index);
  },
  MAIL_SEARCH: ({ commit }, { search, page }) => {
    commit("SEARCH_IN_MAIL", { search, page });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
