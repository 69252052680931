import axios from "@/plugins/axios.js";

const state = {
  autoFetchData: [],
};
const mutations = {
  ADD_FETCHED_DATA: (state, data) => {
    const newArr = [...state.autoFetchData, ...data];
    state.autoFetchData = Array.from(new Set(newArr));
  },
};

const getters = {
  autoFetch: (state) => state.autoFetchData,
};

let fetchMailTimer;
let autoFetchTimer;

const actions = {
  CALL_AUTO_UPDATE: async ({ getters, commit }, regex) => {
    await axios
      .autoFetch({
        nickName: getters.userInfo.nickName,
        regex: regex,
      })
      .then(({ data }) => {
        commit("ADD_FETCHED_DATA", data.nickNames);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  AUTO_FETCH: ({ dispatch }, data) => {
    clearTimeout(autoFetchTimer);
    if (data.length < 3) return;
    autoFetchTimer = setTimeout(() => {
      dispatch("CALL_AUTO_UPDATE", data);
    }, 300);
  },
  NEW_INBOX: ({ dispatch }, data) => {
    if (data.mailType == "replyMail") {
      dispatch("FETCH_REPLY_INBOX_MAIL", data.parentId);
    } else {
      clearTimeout(fetchMailTimer);
      fetchMailTimer = setTimeout(() => {
        dispatch("FETCH_NEW_INBOX_MAILS");
      }, 1000);
    }
  },
  FETCH_REPLY_INBOX_MAIL: async ({ getters, commit }, parentId) => {
    const newData = new Promise((resolve) => {
      axios
        .getSingleInboxMail(getters.userInfo.emailId, parentId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Fetching new mails" + error);
        });
    });

    newData.then((data) => {
      commit("INIT_MAIL_REQUEST");

      const index = getters.getInbox.findIndex(
        (mail) => mail.parentId == parentId
      );

      data.mails.splice(1);

      return commit("NEW_REPLY_SOCKET", {
        mail: data.mails[0],
        index,
      });
    });
  },
  FETCH_NEW_INBOX_MAILS: async ({ getters, commit }) => {
    const newData = new Promise((resolve) => {
      axios
        .getInbox(getters.userInfo.emailId, 1, 50)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Fetching new mails" + error);
        });
    });

    newData.then((data) => {
      commit("INIT_MAIL_REQUEST");

      const tDiff = data.totalCount[0].count - getters.getInboxTotalMails;
      if (getters.getInboxTotalMails == 0 || tDiff > 49)
        return commit("INIT_INBOX_MAIL", { data });
      else if (tDiff > 0) {
        const index = data.mails.findIndex(
          (mail) => mail.parentId == getters.getInbox[0].parentId
        );
        if (index > -1) data.mails.splice(index);

        return commit("NEW_INBOX_SOCKET", {
          newMails: data.mails,
          totalCount: data.totalCount[0].count,
        });
      }
    });
  },

  NEW_SENT_MAILS: ({ getters, commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .getSent(getters.userInfo.nickName, 1, 50)
        .then(({ data }) => {
          commit("INIT_SENT_MAIL", { data });
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            dispatch("ALERT_ERROR", error.response.data.error);
          reject(error);
        });
    });
  },
};

export default { actions, state, mutations, getters };
