import SignUpAPI from "@/axios/api/signup-api";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  SEND_TOKEN: ({ commit }, { body, token }) => {
    commit("AUTH_LOADING");

    return SignUpAPI.sendToken(body, token);
  },
  VALIDATE_TOKEN: ({ commit }, { password, token }) => {
    commit("AUTH_LOADING");

    return SignUpAPI.verifyToken(password, token);
  },
  CREATE_USER: ({ commit }, userData) => {
    commit("AUTH_LOADING");

    return SignUpAPI.createUser(userData);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
