export default {
  path: "/auth",
  component: () => import("../views/authentication/AuthContainer.vue"),
  redirect: "/auth/login",
  children: [
    {
      path: "/auth/login",
      component: () => import("../views/authentication/login/Login.vue"),
      meta: { title: "Login to LedgerMail" },
      name: "login",
    },
    {
      path: "/auth/signup",
      component: () => import("../views/authentication/signup/UserDetails.vue"),
      meta: { title: "Sign up to LedgerMail" },
      name: "signup",
    },
    {
      path: "/auth/email-verification",
      component: () => import("../views/authentication/signup/Signup.vue"),
      meta: { title: "Create Account" },
      name: "create-user",
    },
    {
      path: "/auth/reset-password",
      component: () =>
        import("../views/authentication/login/password/resetPassword.vue"),
      meta: { title: "Create Account" },
      name: "Reset Password",
    },
    {
      path: "/auth/signup/ens",
      component: () => import("../views/authentication/ens/ensSignup.vue"),
      meta: { title: "Signup to LedgerMail using your ENS domain" },
      name: "ENS Signup",
    },
    {
      path: "/auth/signup/wns",
      component: () => import("../views/authentication/wns/wnsSignup.vue"),
      meta: { title: "Signup to LedgerMail using your WNS domain" },
      name: "WNS Signup",
    },
    {
      path: "/auth/signup/xdc",
      component: () => import("../views/authentication/xdc/xdcSignup.vue"),
      meta: { title: "Signup to LedgerMail using your XDC domain" },
      name: "XDC Signup",
    },
    {
      path: "/auth/signup/metamask",
      component: () =>
        import(
          "../views/authentication/login/metamask/stepper/MetaMaskSignup.vue"
        ),
      meta: { title: "Signup to LedgerMail using MetaMask" },
      name: "Signup with MetaMask",
    },
    {
      path: "/auth/signup/free-name",
      component: () =>
        import("../views/authentication/freeName/freeNameSignup.vue"),
      meta: { title: "Signup to LedgerMail using your FreeName domain" },
      name: "FreeName Signup",
    },
  ],
  meta: { noUserAvailable: true },
};
