<template>
  <main>
    <div class="alert">
      <template v-for="(item, i) in alerts">
        <v-alert
          :type="item.type"
          transition="slide-y-transition"
          class="alert--box"
          dismissible
          :key="i"
          @input="closeAlert(i)"
          border="top"
          elevation="2"
        >
          {{ item.message }}
        </v-alert>
      </template>
    </div>
    <template>
      <v-snackbar v-if="snackType == 'info'" v-model.trim="snackbar" right>
        {{ snackMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </main>
</template>

<script>
export default {
  data() {
    return {
      con: 0,
    };
  },
  computed: {
    alerts() {
      return this.$store.getters.getAlerts;
    },
    snackbar: {
      get: function () {
        return this.$store.getters.snackbar;
      },
      set: function (value) {
        this.$store.commit("SNACKBAR_COMPLETE", value);
      },
    },
    snackMessage() {
      return this.$store.getters.snackMessage;
    },
    snackType() {
      return this.$store.getters.snackType;
    },
  },
  methods: {
    closeAlert(index) {
      this.$store.dispatch("CLEAR_ALERT", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  width: max-content;
  width: 80%;
  max-width: 350px;
  z-index: 200000;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);

  &--box {
    transition: all 0.3s ease-out;
  }
}
</style>
