const CryptoJS = require('crypto-js');
import axios from '@/plugins/axios';
import ledgermail_v2 from '@/plugins/ledgermail-jslib';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  DECRYPT_PENDING: async ({ dispatch }, mail) => {
    if (mail.isDecrypt) return;
    mail.isDecrypt = false;

    await dispatch('DATA_DECRYPT', {
      cipherText: mail.body,
      secret: mail.rs,
    }).then((data) => {
      mail.body = data;
      mail.isDecrypt = true;
    });
  },
  DECRYPT_MAIL: async ({ getters, dispatch }, mail) => {
    if (mail.isDecrypt) return;
    else if (!JSON.parse(mail.secrets)[getters.userInfo.emailId]) return;
    mail.isDecrypt = false;

    const randObj = JSON.parse(mail.secrets)[getters.userInfo.emailId];

    await ledgermail_v2
      .decrypt(JSON.parse(randObj), getters.xPrivateKey)
      .then((data) => (mail.randomString = data));

    await dispatch('DATA_DECRYPT', {
      cipherText: mail.body,
      secret: mail.randomString,
    }).then((data) => {
      mail.body = data;
      mail.isDecrypt = true;
    });
  },

  DECRYPT_MAILS_ARRAY: async ({ dispatch }, { mails, randomString }) => {
    if (!mails) return;
    for (let index = 0; index < mails.length; index++) {
      const mail = mails[index];

      if (mail.isDecrypt) return;
      mail.isDecrypt = false;

      await dispatch('DATA_DECRYPT', {
        cipherText: mail.body,
        secret: randomString,
      }).then((data) => {
        mail.body = data;
        mail.randomString = randomString;
        mail.isDecrypt = true;
      });
    }
  },

  DATA_ENCRYPT: ({ commit }, { message, secret }) => {
    commit('DUMMY');
    return new Promise((resolve) => {
      resolve(CryptoJS.AES.encrypt(message, secret).toString());
    });
  },

  DATA_DECRYPT: ({ commit }, { cipherText, secret }) => {
    commit('DUMMY');
    return new Promise((resolve) => {
      var bytes = CryptoJS.AES.decrypt(cipherText, secret);
      resolve(bytes.toString(CryptoJS.enc.Utf8));
    });
  },

  GET_PUBIC_KEYS: async ({ commit, getters }, emailIds) => {
    commit('DUMMY', true);
    emailIds = Array.from(new Set([...emailIds, getters.userInfo.emailId]));

    return new Promise((resolve, reject) => {
      axios
        .getPublicKeys(emailIds)
        .then(async ({ data }) => {
          const mappedNames = Object.keys(data.userData);
          const cmp = Array.from(new Set(emailIds));

          if (mappedNames.length >= cmp.length) resolve(data.userData);
          else {
            const nonExistMails = await cmp.filter(function (n) {
              return mappedNames.indexOf(n) == -1;
            });
            alert(
              'The following recipient(s) are not part of Ledgermail! \n' +
              nonExistMails.toString()
            );
            reject(false);
          }
        })
        .catch((error) => {
          console.error('error', error);

          reject();
        });
    });
  },
};

export default { state, mutations, getters, actions };
