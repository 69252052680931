import axiosData from "@/axios/axios.js";
const { axios, url } = axiosData;

export default {
  sendToken(data, token) {
    return axios.post(`${url.sign_up}/new-user/send-token`, data, {
      headers: { authentication: token },
    });
  },
  verifyToken(password, token) {
    if (window.location.origin.includes(url.enterpriseLink)) {
      return axios.post(`${url.enterprise}/new-user/verify-token`, {
        password,
        token,
      });
    } else {
      return axios.post(`${url.sign_up}/new-user/verify-token`, {
        password,
        token,
      });
    }
  },
  createUser(userData) {
    return axios.post(`${url.sign_up}/signup`, userData);
  },

  uAuthMeta({ id, publicKey }) {
    return axios.get(
      `${url.sign_up}/auth/uauth/meta-data/` + id + "?xKey=" + publicKey
    );
  },
  uAuthCreate(data) {
    return axios.post(`${url.sign_up}/auth/uauth/create-user/`, data);
  },

  ensVerify({ ensName, signature, email, password }) {
    return axios.post(`${url.sign_up}/ens/verify`, {
      ensName,
      signature,
      email,
      password,
    });
  },

  ensSignup({ EnsId }) {
    return axios.post(`${url.sign_up}/ens/signup`, {
      EnsId,
    });
  },

  wnsVerify({ wnsName, signature, email, password }) {
    return axios.post(`${url.sign_up}/wns/verify`, {
      wnsName,
      signature,
      email,
      password,
    });
  },

  wnsSignup({ WnsId }) {
    return axios.post(`${url.sign_up}/wns/signup`, {
      WnsId,
    });
  },
  xdcVerify({ xdcName, signature, email, password }) {
    return axios.post(`${url.sign_up}/xdc/verify`, {
      xdcName,
      signature,
      email,
      password,
    });
  },

  xdcSignup({ XdcId }) {
    return axios.post(`${url.sign_up}/xdc/signup`, {
      XdcId,
    });
  },
  freeNameVerify({ freeName, signature, email, password }) {
    return axios.post(`${url.sign_up}/free-name/verify`, {
      freeName,
      signature,
      email,
      password,
    });
  },

  freeNameSignup({ FreeNameId }) {
    return axios.post(`${url.sign_up}/free-name/signup`, {
      FreeNameId,
    });
  },
};
