import axios from "@/plugins/axios";

const state = {
  referredHistory: [],
};

const mutations = {
  REFERRAL_HISORY: (state, data) => {
    state.referredHistory = data;
  },
};

const actions = {
  FETCH_REFERRALS: ({ getters, commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .getReferDetails(getters.userInfo.emailId, getters.userInfo.address)
        .then(({ data }) => {
          if (!data.error) {
            commit("REFERRAL_HISORY", data.details);
            resolve(data.details);
          } else reject(data.message);
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            reject(error.response.data.error);
          else reject("Request time out: Please try again later!");
        });
    });
  },
};

const getters = {
  getReferralHistory: (state) => state.referredHistory,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
