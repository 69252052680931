import LoginAPI from '@/axios/api/login-api';
import ledgermailV2 from '@/plugins/ledgermail-jslib';

const state = {
  status: 'pending',
};

const mutations = {
  AUTH_LOADING: (state) => {
    state.status = 'loading';
  },
  AUTH_STATUS: (state, value) => {
    state.status = value;
  },
};

const getters = {
  authStatus: (state) => state.status,
};

const actions = {
  AUTH_LOGIN: async ({ commit, dispatch }, { mnemonic, emailId, password }) => {
    const requestTime = new Date();
    commit('AUTH_LOADING');

    const hex = await ledgermailV2.getHexFormMnemonic(mnemonic);
    const publicKey = await ledgermailV2.getXPublicKey(hex);
    const xPrivateKey = await ledgermailV2.getXPrivateKey(hex);

    const tempMnemonic = ledgermailV2.generateMnemonic()
    const tempHex = await ledgermailV2.getHexFormMnemonic(tempMnemonic);
    const tempExtPublicKey = await ledgermailV2.getXPublicKey(tempHex);
    const tempExtPrivateKey = await ledgermailV2.getXPrivateKey(tempHex);
    const { data } = await LoginAPI.getAccessKey(tempExtPublicKey)
    const accessKey = await ledgermailV2.decrypt(JSON.parse(data.accessKey), tempExtPrivateKey)

    const encPassword = await ledgermailV2.encrypt(password, accessKey)


    return new Promise((resolve, reject) => {
      LoginAPI.login({ emailId, password: encPassword, publicKey, accessKey })
        .then(({ data }) => {
          if (data.error) throw new Error(data.message);

          if (data.tokenExpire == '1d')
            requestTime.setDate(requestTime.getDate() + 1);

          const encKey = ledgermailV2.ASEEncrypt(xPrivateKey, `${data.user.emailId}oo$#`);
          const encMnemonic = ledgermailV2.ASEEncrypt(mnemonic, password);

          commit("STORE_USER_PASSWORD", password)
          const userInfo = { ...data.user, publicKey, encKey, mnemonic: encMnemonic };

          localStorage.setItem(
            'auth-token',
            JSON.stringify({ token: data.token, exp: requestTime })
          );
          localStorage.setItem('user-info', JSON.stringify(userInfo));

          commit('STORE_USER_DETAILS', userInfo);
          commit('AUTH_STATUS', 'Success');
          resolve();
        })
        .catch((error) => {
          console.error('error: ', error);
          commit('AUTH_STATUS', 'Error');
          dispatch('AUTH_LOGOUT');

          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            reject(error.response.data.error);
          else
            reject(
              error.message
                ? error.message
                : 'Request time out: Please try again later!'
            );
        });
    });
  },

  AUTH_LOGOUT: () => {
    localStorage.removeItem('user-info');
    localStorage.removeItem('auth-token');
    return;
  },

  SEND_PASSWORD_RESET_TOKEN: async ({ commit }, { email, mnemonic }) => {
    commit('DUMMY');

    const publicKey = await ledgermailV2.getXPublicKey(
      await ledgermailV2.getHexFormMnemonic(mnemonic)
    );

    const authKey = ledgermailV2.ASEEncrypt(mnemonic, `${email}oo$#pk`);

    return new Promise((resolve, reject) => {
      LoginAPI.sendPasswordResetToken({ email, publicKey, authKey })
        .then(({ data }) => {
          if (data.error) reject(data.message);
          else resolve(data);
        })
        .catch((error) => {
          console.error('[SEND_PASSWORD_RESET_TOKEN] ERROR: ', error);
          reject('Request time out: Please try again later!');
        });
    });
  },

  RESET_PASSWORD: async ({ commit }, { email, token, password }) => {
    commit('DUMMY');

    return new Promise((resolve, reject) => {
      LoginAPI.changePassword({ email, token, password })
        .then(({ data }) => {
          if (data.error) reject(data.message);
          else resolve(data);
        })
        .catch((error) => {
          console.error('[RESET_PASSWORD] ERROR: ', error);
          reject('Request time out: Please try again later!');
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
