import axiosData from '@/axios/axios.js';
const { axios, url, getHeaders } = axiosData;

export default {
    getUserDetails(emailIds) {
        return axios.post(
            `${url.new_compose}/auth/user-details`,
            { emailIds },
            { headers: getHeaders() }
        );
    },
    enterpriseUsers() {
        return axios.get(
            `${url.user_ctrl}/auth/user-details/fdppi-users`,
            {},
            { headers: getHeaders() }
        );
    },
    verifyFileCoinDomain(newDomainName) {
        return axios.post(
            `${url.user_ctrl}/auth/file-coin-domain/check`,
            { newDomainName },
            { headers: getHeaders() }
        );
    },
    lockFileCoinDomain(newDomainName, accessKey) {
        return axios.post(
            `${url.user_ctrl}/auth/file-coin-domain/lock`,
            { newDomainName, accessKey },
            { headers: getHeaders() }
        );
    },
    deleteUserAccount() {
        return axios.get(`${url.user_ctrl}/auth/delete-account`, {
            headers: getHeaders()
        });
    }
};
