import Vue from 'vue';
import VueRouter from 'vue-router';

import axiosData from '@/axios/axios.js';
const { url } = axiosData;

import PageNotFound from '../views/PageNotFound';
import authRoutes from './authRoutes';
import dashboardRoutes from './dashboardRoutes';
import deepLinkingRoutes from './deepLinkingRoutes';
import referralRoutes from './referralRoutes';
import unstoppableRoutes from './unstoppableRoutes';

import adminRoutes from './adminRoutes';
Vue.use(VueRouter);

const routes = [
    authRoutes,
    unstoppableRoutes,
    referralRoutes,
    dashboardRoutes,
    deepLinkingRoutes,
    adminRoutes,
    {
        path: '/accept-request',
        component: () =>
            import('../views/admin/AcceptRequestForExistingUsers.vue'),
        meta: {
            skipGuard: true
        }
    },
    {
        path: '/get-wallet-private',
        component: () => import('../views/walletPrivateKey.vue'),
        meta: {
            title: 'Private key from Seed words',
            skipGuard: true
        }
    },
    {
        path: '/steps-to-delete-ledgermail-account',
        component: () => import('../views/pages/HowToDeleteAccount.vue'),
        meta: {
            title: 'Private key from Seed words',
            skipGuard: true
        }
    },
    {
        path: '/',
        redirect: '/mail'
    },
    {
        path: '/dev',
        redirect: '/mail'
    },
    {
        path: '/preprod',
        redirect: '/mail'
    },
    {
        path: '/admin',
        meta: { isAdmin: true },
        name: 'admin'
    },
    {
        path: '/page-not-found',
        name: 'Page not found',
        component: PageNotFound
    },
    {
        path: '*',
        name: '#404',
        component: PageNotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'is-route-active',
    linkExactActiveClass: 'exact-active',
    base: process.env.BASE_URL,
    routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    if (originalPush)
        return originalPush.call(this, location).catch((err) => {
            if (err.name !== 'NavigationDuplicated') throw err;
        });
    else return;
};

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'LedgerMail';

    if (
        window.location.origin.includes(url.enterpriseLink) &&
        to.fullPath.includes('/auth/signup')
    ) {
        return next('/page-not-found');
    }

    const skipGuard = to.matched.some((record) => record.meta.skipGuard);
    if (skipGuard) next();

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAdminRoute = to.matched.some((item) => item.meta.isAdmin);

    const noUserAvailable = to.matched.some(
        (record) => record.meta.noUserAvailable
    );

    // if (
    //   process.env.VUE_APP_OPEN_ROUTES &&
    //   process.env.VUE_APP_OPEN_ROUTES == "enable"
    // )
    //   next();
    // else
    try {
        const status = JSON.parse(localStorage.getItem('auth-token') || '{}');
        const currentUser = JSON.parse(
            localStorage.getItem('user-info') || '{}'
        );

        if (isAdminRoute && status.token) {
            if (!currentUser.roles || !currentUser.roles.includes('admin'))
                return next('/');

            if (!to.params.email || to.params.email !== currentUser.emailId)
                return next({
                    name: to.name,
                    params: { ...to.params, email: currentUser.emailId }
                });
            return next();
        }

        if (requiresAuth && !status.token) next('/auth/login');
        else if (noUserAvailable && !!status.token) next('/');
        else next();
    } catch (error) {
        console.error('ROUTER_ERROR', error);
        next('/auth/login');
    }
});

export default router;
