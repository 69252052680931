import ledgermailV2 from "@/plugins/ledgermail-jslib";
import axiosData from "@/axios/axios.js";
const { url } = axiosData;

const state = {
  userInfo: JSON.parse(localStorage.getItem("user-info") || "{}"),
  userBalance: -1,
  userPassword: "",
  encKey: "",
};

// const tempUserInfo = JSON.parse(localStorage.getItem("user-info") || "{}");
// tempUserInfo.firstName = this.firstName;
// localStorage.setItem("user-info", JSON.stringify(tempUserInfo));

const mutations = {
  STORE_USER_PASSWORD: (state, password) => {
    state.userPassword = password;
  },
  STORE_USER_DETAILS: (state, details) => {
    state.userInfo = details;
  },
  AUTH_SET_BALANCE: (state, value) => {
    state.userBalance = value;
  },
};

const getters = {
  userInfo: (state) => state.userInfo,
  haveUserPassword: (state) => (state.userPassword.length > 0 ? true : false),
  userPassword: (state) => state.userPassword,
  xPrivateKey: (state) =>
    ledgermailV2.ASEDecrypt(
      state.userInfo.encKey,
      `${state.userInfo.emailId}oo$#`
    ),
  balance: (state) => state.userBalance,
  userBalance: (state) => state.userBalance,
  pk: (state) => {
    if (state.userInfo.isWeb3Auth) {
      return ledgermailV2.ASEEncrypt(
        state.userPassword,
        `${state.userInfo.emailId}oo$#pk`
      );
    } else if (state.userPassword.length > 0) {
      const mnemonic = ledgermailV2.ASEDecrypt(
        state.userInfo.mnemonic,
        state.userPassword
      );

      return ledgermailV2.ASEEncrypt(
        mnemonic,
        `${state.userInfo.emailId}oo$#pk`
      );
    } else return "";
  },
  encKey: (state) => state.userInfo.encKey,
  isEnterprise: (state) => {
    if (!state.userInfo.enterprise) return false;
    else if (state.userInfo.enterprise.length > 1) return true;
    else if (
      state.userInfo.enterprise[0] !== null &&
      state.userInfo.enterprise[0] !== undefined &&
      state.userInfo.enterprise[0] !== "" &&
      state.userInfo.enterprise[0] !== "public" &&
      state.userInfo.enterprise.length
    )
      return true;

    return false;
  },
  isEnterpriseSite: () => {
    if (window.location.origin.includes(url.enterpriseSiteLink)) return true;
    return false;
  },
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
