export default {
  path: "/admin/:email",
  name: "admin-dashboard",
  component: () => import("../views/mail/Dashboard.vue"),
  redirect: "/admin/:email/users",
  children: [
    {
      path: "/admin/:email/audit",
      component: () => import("../views/admin/Audit.vue"),
      meta: { title: `Audit User` },
      name: "audit",
    },
    {
      path: "/admin/:email/add-users",
      component: () => import("../views/admin/AddUsers.vue"),
      meta: { title: `Add New User` },
      name: "add-users",
    },
    {
      path: "/admin/:email/users",
      component: () => import("../views/admin/Users.vue"),
      meta: { title: `All Users` },
      name: "users",
    },
  ],
  meta: { isAdmin: true },
};
