import ComposeAPI from "@/axios/api/compose-api";

const state = {
  pendingMails: [],
  isFetchingPending: false,
};

const mutations = {
  UPDATE_PENDING_STATUS: (state, value) => {
    state.isFetchingPending = value;
  },
  ADD_PENDING_MAIL: (state, value) => {
    state.pendingMails = value;
  },
};

const getters = {
  pendingMails: (state) => state.pendingMails,
  isFetchingPending: (state) => state.isFetchingPending,
};

const actions = {
  FETCH_PENDING: async ({ commit }) => {
    commit("UPDATE_PENDING_STATUS", true);
    const { data } = await ComposeAPI.getPendingMails();

    commit("ADD_PENDING_MAIL", data.mails);
    commit("UPDATE_PENDING_STATUS", false);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
