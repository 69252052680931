export default {
  path: "/uauth",
  component: () =>
    import("../views/authentication/unstoppable/uAuthContainer.vue"),
  redirect: "/uauth/signup",
  children: [
    {
      path: "/uauth/signup",
      component: () =>
        import("../views/authentication/unstoppable/UAuthPassword.vue"),
      meta: { title: "Sign up to LedgerMail" },
      name: "uauth-signup",
    },
    {
      path: "/uauth/error",
      component: () =>
        import("../views/authentication/unstoppable/UnstoppableError.vue"),
      meta: { title: "Server not responding" },
      name: "uauth-error",
    },
  ],
  meta: { noUserAvailable: true },
};
