import LoginAPI from "@/axios/api/login-api";
import UserAPI from "@/axios/api/user-api";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function firstN_NameWithoutName(obj, n) {
  return Object.keys(obj)
    .sort()
    .filter((e) => !obj[e].firstName)
    .slice(0, n);
}

var passwordCheckInterval;

const state = {
  dummy: 0,
  initialLoading: false,
  partialLoading: false,
  showPasswordDialog: false,

  localMailSearch: "",
  userNames: {},
};

const mutations = {
  SET_USR_NAME: (state, data) => (state.userNames = data),
  SET_INITIAL_LOADING_STATUS: (state, status) => {
    state.initialLoading = typeof status == "boolean" ? status : false;
  },
  SET_PARTIAL_LOADING_STATUS: (state, status) => {
    state.partialLoading = typeof status == "boolean" ? status : false;
  },

  LOCAL_MAIL_SEARCH: (state, value) => {
    state.localMailSearch = value;
  },
  /****** To simplify calling actions methods ******/
  DUMMY: (state) => {
    state.dummy = 1;
  },

  SHOW_PASSWORD_DIALOG_STATUS: (state, value) => {
    state.showPasswordDialog = value;
  },
  CLEAR_PASSWORD_INTERVAL: () => {
    clearInterval(passwordCheckInterval);
  },
};

const getters = {
  partialLoading: (state) => state.partialLoading,
  initialLoading: (state) => state.initialLoading,

  userNames: (state) => state.userNames,
  localMailSearch: (state) => state.localMailSearch,
  showPasswordDialog: (state) => state.showPasswordDialog,
};

const actions = {
  CONTINUE_AFTER_PWD: ({ getters }) => {
    return new Promise((resolve) => {
      passwordCheckInterval = setInterval(() => {
        if (!getters.showPasswordDialog) {
          clearInterval(passwordCheckInterval);
          resolve();
        }
      }, 1000);
    });
  },
  REFRESH_TOKEN_WITH_PASSWORD: async ({ commit, dispatch }) => {
    commit("SHOW_PASSWORD_DIALOG_STATUS", true);
    await dispatch("CONTINUE_AFTER_PWD");
    return true;
  },

  REFRESH_TOKEN: async ({ getters }) => {
    const requestTime = new Date();

    try {
      const { data } = await LoginAPI.refreshToken(
        getters.userInfo.emailId,
        getters.userInfo.publicKey
      );

      if (data.tokenExpire == "1d")
        requestTime.setDate(requestTime.getDate() + 1);

      localStorage.setItem(
        "auth-token",
        JSON.stringify({ token: data.token, exp: requestTime })
      );
      return true;
    } catch (error) {
      console.error("error: ", error);
      return false;
    }
  },

  DASHBOARD_INIT: async ({ dispatch }) => {
    await dispatch("CHECK_PROFILE_IMAGE_EXISTS")


    await dispatch("MAIL_INIT");
    dispatch("ENTERPRISE_INIT")
    dispatch("FETCH_PENDING");

  },
  GLB_INIT: ({ dispatch }) => {
    dispatch("CHECK_USER_NAME");
  },
  CHECK_USER_NAME: async ({ commit, dispatch }) => {
    const cookieString = getCookie("_lm_us_ck");

    if (!cookieString.length) return;

    const temp = await dispatch("DATA_DECRYPT", {
      cipherText: cookieString,
      secret: "this.randomString+*$lm",
    });

    const userDetails = JSON.parse(temp || "{}");
    const fetchData = firstN_NameWithoutName(userDetails, 50);

    try {
      if (!fetchData.length) return commit("SET_USR_NAME", userDetails);
      const { data } = await UserAPI.getUserDetails(fetchData);
      if (!data) throw new Error("Server not reachable");
      if (data.error) throw new Error(data);

      for (let index = 0; index < Object.keys(data.userData).length; index++) {
        userDetails[Object.keys(data.userData)[index]] =
          data.userData[Object.keys(data.userData)[index]];
      }
    } catch (error) {
      console.error(error);
    }

    commit("SET_USR_NAME", userDetails);

    const cookieData = await dispatch("DATA_ENCRYPT", {
      message: JSON.stringify(userDetails),
      secret: "this.randomString+*$lm",
    });

    if (process.env.NODE_ENV == "development")
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/;`;
    else
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/; domain=.${window.document.location.hostname}`;
  },
  STORE_USER_NAMES: async ({ getters, dispatch }, mailsArray) => {
    let AllUsers = getters.userNames;

    for (let index = 0; index < mailsArray.length; index++) {
      const element = mailsArray[index];

      if (!AllUsers[element.sender])
        AllUsers[element.sender] = { email: element.sender };
      element.receiver.forEach((e) => {
        if (!AllUsers[e]) AllUsers[e] = { email: e };
      });
      element.cc.forEach((e) => {
        if (!AllUsers[e]) AllUsers[e] = { email: e };
      });
    }

    const cookieData = await dispatch("DATA_ENCRYPT", {
      message: JSON.stringify(AllUsers),
      secret: "this.randomString+*$lm",
    });

    if (process.env.NODE_ENV == "development")
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/;`;
    else
      window.document.cookie = `_lm_us_ck=${cookieData}; max-age=604800; path=/; domain=.${window.document.location.hostname}`;

    dispatch("CHECK_USER_NAME");
  },

  setLocalMailSearch: ({ commit, state }, newValue) => {
    commit("LOCAL_MAIL_SEARCH", newValue);
    return state.localMailSearch;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
