import axiosData from '@/axios/axios.js';
const { axios, url, getHeaders } = axiosData;

export default {
  saveDraft(body) {
    return axios.post(
      `${url.new_compose}/auth/draft/save`,
      body,
      { headers: getHeaders() }
    );
  },
  deleteDraft(id) {
    return axios.post(
      `${url.new_compose}/auth/draft/delete`,
      { id },
      { headers: getHeaders() }
    );
  },
  getWalletInfo(body) {
    return axios.post(
      `${url.user_ctrl}/auth/user-details/get-wallet-info`,
      body,
      { headers: getHeaders() }
    );
  },
  sendNewMail(body, token) {
    return axios.post(`${url.new_compose}/auth/compose/send-mail`, body, {
      headers: { ...getHeaders(), authentication: token },
    });
  },
  verifyMail(emailIds) {
    return axios.post(
      `${url.new_compose}/auth/compose/verify`,
      { emailIds },
      { headers: getHeaders() }
    );
  },

  getPendingMails() {
    return axios.post(
      `${url.mail_filter}/auth/filter/pending-mails`,
      {},
      { headers: getHeaders() }
    );
  },
  reSendPendingMail(id) {
    return axios.post(
      `${url.mail_filter}/auth/filter/pending-mails-resend`,
      { id },
      { headers: getHeaders() }
    );
  },
  deletePendingMail(id) {
    return axios.post(
      `${url.mail_filter}/auth/filter/pending-mails-delete`,
      { id },
      { headers: getHeaders() }
    );
  },
};
