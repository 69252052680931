import axiosData from "@/axios/axios.js";
const { axios, url, getHeaders } = axiosData;

export default {
  updateUserData(data) {
    return axios.post(`${url.user_ctrl}/auth/user-details/update`, data, {
      headers: getHeaders(),
    });
  },
  fetchUserData() {
    return axios.post(
      `${url.user_ctrl}/auth/user-details/fetch`,
      {},
      { headers: getHeaders() }
    );
  },
  updateProfileImage(data) {
    return axios.post(`${url.user_ctrl}/auth/user-details/update-img`, data, {
      headers: getHeaders(),
    });
  },

  fetchProfileImage(email) {
    return axios.get(`${url.user_ctrl}/profile-image/` + email);
  },
  userProfileMeta(email) {
    return axios.get(`${url.user_ctrl}/auth/user-details/usr-img-meta/${email}`, {
      headers: getHeaders(),
    });
  },
  inviteUser(emailId) {
    return axios.post(
      `${url.user_ctrl}/auth/invite`,
      { email: emailId },
      {
        headers: getHeaders(),
      }
    );
  },
};
