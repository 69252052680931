import axios from "@/plugins/axios.js";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  SEEN_MAIL: ({ getters }, mail) => {
    if (!mail) return;
    if (mail.seen && mail.seen.includes(getters.userInfo.emailId)) return;

    mail.seen = [getters.userInfo.emailId];

    let email = "";
    let type = "";

    const receiversInDomain = mail.receiver.filter(value => getters.userInfo.domainName.includes(value))
    const ccInDomain = mail.cc.filter(value => getters.userInfo.domainName.includes(value))

    if (mail.sender == getters.userInfo.emailId) {
      type = "sender";
      email = mail.sender
    }
    else if (getters.userInfo.domainName.includes(mail.sender)) {
      type = "sender";
      email = mail.sender
    }
    else if (mail.receiver.includes(getters.userInfo.emailId)) {
      type = "receiver";
      email = getters.userInfo.emailId
    } else if (receiversInDomain.length) {
      type = "receiver";
      email = receiversInDomain[0]
    }
    else if (mail.cc.includes(getters.userInfo.emailId)) {
      type = "cc";
      email = getters.userInfo.emailId
    } else if (ccInDomain.length) {
      type = "receiver";
      email = ccInDomain[0]
    }
    return axios.seenMail(email, mail._id, type);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
