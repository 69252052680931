import axios from '@/plugins/axios.js';
import router from '@/router';
import data from '@/axios/axios';
const { url } = data;

const state = {
    mailStatus: 'pending',
    inboxMails: [],
    sentMails: [],
    draftMails: [],
    inboxTotalMails: 0,
    sentTotalMails: 0,
    draftTotalMails: 0,
    draftPageOptions: {
        itemsPerPage: 50,
        page: 1
    },
    sentPageOptions: {
        itemsPerPage: 50,
        page: 1
    },
    inboxPageOptions: {
        itemsPerPage: 50,
        page: 1
    },
    archivePageOptions: {
        itemsPerPage: 50,
        page: 1
    },
    archiveMails: [],
    archiveTotalMails: 0,

    showLoading: {
        sent: false,
        draft: false,
        inbox: false,
        archive: false
    }
};

const mutations = {
    SHOW_LOADING: (state, type) => {
        if (type == 'INBOX') state.showLoading.inbox = true;
        else if (type == 'SENT') state.showLoading.sent = true;
        else if (type == 'ARCHIVE') state.showLoading.archive = true;
        else if (type == 'DRAFT') state.showLoading.draft = true;
    },

    INIT_ARCHIVE_MAIL: (state, { data }) => {
        state.archiveMails = data.mails;
        state.archiveTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
        state.showLoading.archive = false;
    },
    PUSH_ARCHIVE: (state, { index, mail }) => {
        state.archiveTotalMails += 1;
        state.archiveMails.splice(index, 0, mail);
    },
    UPDATE_ARCHIVE: (state, { data }) => {
        if (data.skip > state.archiveMails.length)
            state.archiveMails = state.archiveMails.concat(data.mails);
        else if (state.archiveMails.length == data.mails.length) return;
        else state.archiveMails = data.mails;
        state.archiveTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
    },
    UPDATE_TOTAL_INBOX: (state, value) => {
        state.inboxTotalMails += value;
    },
    UPDATE_TOTAL_SENT: (state, value) => {
        state.sentTotalMails += value;
    },
    UPDATE_TOTAL_DRAFT: (state, value) => {
        state.draftTotalMails += value;
    },
    UPDATE_TOTAL_ARCHIVE: (state, value) => {
        state.archiveTotalMails += value;
    },

    INIT_MAIL_REQUEST: (state) => {
        state.mailStatus = 'loading';
    },
    INIT_MAIL_SUCCESS: () => {
        state.mailStatus = 'success';
    },

    PUSH_INBOX: (state, { index, mail }) => {
        state.inboxMails.splice(index, 0, mail);
        state.inboxTotalMails += 1;
    },
    PUSH_SENT: (state, { index, mail }) => {
        state.sentMails.splice(index, 0, mail);
        state.sentTotalMails += 1;
    },
    PUSH_DRAFT: (state, { index, mail }) => {
        state.draftMails.splice(index, 0, mail);
        state.draftTotalMails += 1;
    },

    INIT_INBOX_MAIL: (state, { data }) => {
        state.inboxMails = data.mails;
        state.inboxTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
        state.showLoading.inbox = false;
    },
    INIT_SENT_MAIL: (state, { data }) => {
        state.sentMails = data.mails;
        state.sentTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
        state.showLoading.sent = false;
    },
    INIT_DRAFT_MAIL: (state, { data }) => {
        state.draftMails = data.mails;
        state.draftTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
        state.showLoading.draft = false;
    },

    NEW_REPLY_SOCKET: (state, { mail, index }) => {
        state.inboxMails.splice(index, 1);
        state.inboxMails = [mail, ...state.inboxMails];
        state.mailStatus = 'success';
    },
    NEW_INBOX_SOCKET: (state, { newMails, totalCount }) => {
        state.inboxMails = [...newMails, ...state.inboxMails];
        state.inboxTotalMails = totalCount;
        state.mailStatus = 'success';
    },

    UPDATE_INBOX: (state, { data }) => {
        state.inboxMails = [...state.inboxMails, ...data.mails];
        state.inboxTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
    },
    UPDATE_SENT: (state, { data, skipValidation, isReply }) => {
        if (isReply) {
            if (!state.sentTotalMails) {
                state.sentMails = data.mails;
                state.sentTotalMails = 1;
                state.mailStatus = 'success';
                return;
            }

            let index = data.mails.findIndex(
                (mail) => mail.parentId == state.sentMails[0].parentId
            );
            const newReplyMails = data.mails.slice(0, index);

            for (
                let newIndex = 0;
                newIndex < newReplyMails.length;
                newIndex++
            ) {
                let index = state.sentMails.findIndex(
                    (mail) => mail.parentId == newReplyMails[newIndex].parentId
                );
                if (index > -1) state.sentMails.splice(index, 1);
            }

            state.sentMails = [...newReplyMails, ...state.sentMails];
            state.sentTotalMails = data.totalCount[0].count;
        } else if (skipValidation) {
            const diff = data.totalCount[0].count - state.sentTotalMails;
            if (diff <= 0) return (state.mailStatus = 'success');

            state.sentMails = [
                ...data.mails.slice(0, diff),
                ...state.sentMails
            ];
            state.sentTotalMails = data.totalCount[0].count;
        } else {
            state.sentMails = [...state.sentMails, ...data.mails];
            state.sentTotalMails = data.totalCount[0]
                ? data.totalCount[0].count
                : 0;
        }
        state.mailStatus = 'success';
    },
    UPDATE_DRAFT: (state, { data }) => {
        state.draftMails = [...state.draftMails, ...data.mails];
        state.draftTotalMails = data.totalCount[0]
            ? data.totalCount[0].count
            : 0;
        state.mailStatus = 'success';
    },

    INIT_MAIL_ERROR: (state) => {
        state.mailStatus = 'error';
    },
    RESET_MAIL_STAGE: (state) => {
        state.mailStatus = 'pending';
        state.inboxMails = [];
        state.sentMails = [];
        state.draftMails = [];
        state.dummy = true;
    },
    UPDATE_SENT_PAGE_OPTIONS: (state, options) => {
        state.sentPageOptions = options;
    },
    UPDATE_DRAFT_PAGE_OPTIONS: (state, options) => {
        state.draftPageOptions = options;
    },
    UPDATE_INBOX_PAGE_OPTIONS: (state, options) => {
        state.inboxPageOptions = options;
    },
    UPDATE_ARCHIVE_PAGE_OPTIONS: (state, options) => {
        state.archivePageOptions = options;
    }
};

const getters = {
    initArchiveLoading: (state) => state.showLoading.archive,

    initMailStatus: (state) => state.mailStatus,
    getInbox: (state) => state.inboxMails,
    getSent: (state) => state.sentMails,
    getDraft: (state) => state.draftMails,
    getInboxTotalMails: (state) => state.inboxTotalMails,
    getSentTotalMails: (state) => state.sentTotalMails,
    getDraftTotalMails: (state) => state.draftTotalMails,

    getArchive: (state) => state.archiveMails,
    getArchiveTotalMails: (state) => state.archiveTotalMails,
    getSentPageOptions: (state) => state.sentPageOptions,
    getDraftPageOptions: (state) => state.draftPageOptions,
    getInboxPageOptions: (state) => state.inboxPageOptions,
    getArchivePageOptions: (state) => state.archivePageOptions
};

const actions = {
    MAIL_INIT: async ({ getters, commit, dispatch }) => {
        // if (
        //   process.env.VUE_APP_OPEN_ROUTES &&
        //   process.env.VUE_APP_OPEN_ROUTES == "enable"
        // )
        //   return;

        commit('INIT_MAIL_REQUEST');
        commit('SET_INITIAL_LOADING_STATUS', true);

        try {
            const balanceRes = await axios.getBalance(getters.userInfo.emailId);
            commit('AUTH_SET_BALANCE', balanceRes.data.balance);
        } catch (error) {
            console.error('error: ', error);
            if (error.response && error.response.status === 401) {
                if (await dispatch('REFRESH_TOKEN_WITH_PASSWORD'))
                    return dispatch('MAIL_INIT');
                else
                    dispatch('AUTH_LOGOUT').then(() => {
                        router.push('/auth/login');
                    });
            } else {
                dispatch(
                    'ALERT_ERROR',
                    'Something went wrong! Please try after sometime'
                );
                dispatch('AUTH_LOGOUT').then(() => {
                    router.push('/auth/login');
                });
            }
            return;
        }
        commit('UPDATE_SENT_PAGE_OPTIONS', { page: 1, itemsPerPage: 50 });
        commit('UPDATE_DRAFT_PAGE_OPTIONS', { page: 1, itemsPerPage: 50 });
        commit('UPDATE_INBOX_PAGE_OPTIONS', { page: 1, itemsPerPage: 50 });
        commit('UPDATE_ARCHIVE_PAGE_OPTIONS', { page: 1, itemsPerPage: 50 });
        if (dispatch('CHECK_PROFILE_IMAGE_EXISTS', getters.userInfo.emailId))
            commit(
                'SET_PROFILE_IMAGE',
                url.user_ctrl + '/profile-image/' + getters.userInfo.emailId
            );
        const inboxMails = axios.getInbox(getters.userInfo.emailId, 1, 50, '');
        const sentMails = axios.getSent(getters.userInfo.emailId, 1, 50, '');
        const draftMails = axios.getDraft(1, 50, '');

        commit('SHOW_LOADING', 'ARCHIVE');
        axios
            .getArchive(getters.userInfo.emailId, 1, 50, '')
            .then(({ data }) => {
                commit('INIT_ARCHIVE_MAIL', { data });
            });

        return new Promise((resolve, reject) => {
            Promise.all([inboxMails, sentMails, draftMails])
                .then((data) => {
                    commit('INIT_INBOX_MAIL', { data: data[0].data });
                    commit('INIT_SENT_MAIL', { data: data[1].data });
                    commit('INIT_DRAFT_MAIL', { data: data[2].data });

                    let checkArray = [
                        ...data[0].data.mails,
                        ...data[1].data.mails
                    ];
                    dispatch('STORE_USER_NAMES', checkArray);

                    commit('INIT_MAIL_SUCCESS');
                    commit('SET_INITIAL_LOADING_STATUS', false);
                    resolve();
                })
                .catch(async (error) => {
                    commit('INIT_MAIL_ERROR');
                    if (error.response && error.response.status === 401) {
                        if (await dispatch('REFRESH_TOKEN_WITH_PASSWORD'))
                            return dispatch('MAIL_INIT');
                        else
                            dispatch('AUTH_LOGOUT').then(() => {
                                router.push('/auth/login');
                            });
                    }
                    console.error(error);
                    reject(error);
                });
        });
    },
    GET_BALANCE: ({ getters, commit, dispatch }) => {
        axios
            .getBalance(getters.userInfo.emailId)
            .then(({ data }) => {
                commit('AUTH_SET_BALANCE', data.balance);
            })
            .catch((error) => {
                console.error('[FETCH BALANCE]', error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                )
                    dispatch('ALERT_ERROR', error.response.data.error);
                else dispatch('ALERT_ERROR', 'Unable to fetch balance');
            });
    },
    GET_INBOX_MAILS: (
        { getters, commit, dispatch },
        { pageNo, limit, key, refreshData }
    ) => {
        if (
            getters.getInbox.length > (pageNo - 1) * limit &&
            !key &&
            !refreshData
        )
            return;
        return new Promise((resolve, reject) => {
            axios
                .getInbox(
                    getters.userInfo.emailId,
                    pageNo,
                    limit,
                    key ? key : ''
                )
                .then(({ data }) => {
                    if (!refreshData) commit('UPDATE_INBOX', { data: data });
                    else commit('INIT_INBOX_MAIL', { data: data });
                    resolve();
                })
                .catch((error) => {
                    commit('INIT_MAIL_ERROR');
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    )
                        dispatch('ALERT_ERROR', error.response.data.error);
                    reject(error);
                });
        });
    },
    GET_SENT_MAILS: (
        { getters, commit, dispatch },
        { pageNo, limit, skipValidation, isReply, key, refreshData }
    ) => {
        if (skipValidation || isReply) {
            pageNo = pageNo || 1;
            limit = limit || 50;
        } else if (
            getters.getSent.length > (pageNo - 1) * limit &&
            !key &&
            !refreshData
        ) {
            return;
        }

        return new Promise((resolve, reject) => {
            axios
                .getSent(
                    getters.userInfo.emailId,
                    pageNo,
                    limit,
                    key ? key : ''
                )
                .then(({ data }) => {
                    if (!refreshData)
                        commit('UPDATE_SENT', {
                            data: data,
                            skipValidation,
                            isReply
                        });
                    else commit('INIT_SENT_MAIL', { data: data });
                    resolve();
                })
                .catch((error) => {
                    commit('INIT_MAIL_ERROR');
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    )
                        dispatch('ALERT_ERROR', error.response.data.error);
                    reject(error);
                });
        });
    },
    GET_DRAFT_MAILS: (
        { getters, commit, dispatch },
        { pageNo, limit, key, refreshData }
    ) => {
        if (
            getters.getDraft.length > (pageNo - 1) * limit &&
            !key &&
            !refreshData
        )
            return;
        return new Promise((resolve, reject) => {
            axios
                .getDraft(pageNo, limit, key ? key : '')
                .then(({ data }) => {
                    if (!refreshData) commit('UPDATE_DRAFT', { data: data });
                    else commit('INIT_DRAFT_MAIL', { data: data });
                    resolve();
                })
                .catch((error) => {
                    commit('INIT_MAIL_ERROR');
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    )
                        dispatch('ALERT_ERROR', error.response.data.error);
                    reject(error);
                });
        });
    },

    REFRESH_MAILS: async ({ getters, commit, dispatch }, type) => {
        commit('SHOW_LOADING', type);
        if (type == 'INBOX')
            axios
                .getInbox(
                    getters.userInfo.emailId,
                    getters.getInboxPageOptions.page,
                    getters.getInboxPageOptions.itemsPerPage,
                    getters.inboxSearch
                )
                .then(({ data }) => {
                    commit('INIT_INBOX_MAIL', { data });
                    dispatch('STORE_USER_NAMES', data.mails);
                })
                .catch(() => {
                    dispatch('MAIL_INIT');
                });
        else if (type == 'DRAFT')
            axios
                .getDraft(
                    getters.getDraftPageOptions.page,
                    getters.getDraftPageOptions.itemsPerPage
                )
                .then(({ data }) => {
                    commit('INIT_DRAFT_MAIL', { data });
                })
                .catch(() => {
                    dispatch('MAIL_INIT');
                });
        else if (type == 'SENT')
            axios
                .getSent(
                    getters.userInfo.emailId,
                    getters.getSentPageOptions.page,
                    getters.getSentPageOptions.itemsPerPage,
                    getters.sentSearch
                )
                .then(({ data }) => {
                    commit('INIT_SENT_MAIL', { data });
                    dispatch('STORE_USER_NAMES', data.mails);
                })
                .catch((err) => {
                    console.error('refresh mail [sent]: ', err);
                    dispatch('MAIL_INIT');
                });
        else if (type == 'ARCHIVE') {
            commit('INIT_MAIL_SUCCESS');
            commit('AM_LOADING');
            setTimeout(() => {
                commit('AM_COMPLETE');
            }, 3000);
            axios
                .getArchive(
                    getters.userInfo.emailId,
                    getters.getArchivePageOptions.page,
                    getters.getArchivePageOptions.itemsPerPage,
                    getters.archiveSearch
                )
                .then(({ data }) => {
                    commit('INIT_ARCHIVE_MAIL', { data });
                    dispatch('STORE_USER_NAMES', data.mails);
                })
                .catch(() => {
                    dispatch('MAIL_INIT');
                });
        } else if (type == 'ALL') dispatch('MAIL_INIT');
    },

    COMPOSE_MAIL: async (
        { getters },
        { sender, receiver, subject, body, attachments, CC, secrets }
    ) => {
        return new Promise((resolve, reject) => {
            axios
                .composeMail({
                    userWallet: getters.userInfo.address,
                    sender,
                    receiver,
                    cc: CC,
                    subject,
                    body,
                    attachments,
                    secrets
                })
                .then(({ data }) => {
                    if (!data.error) {
                        const mailData = {
                            recipients: [
                                ...data.mailData.receiver,
                                ...data.mailData.cc
                            ],
                            mailType: data.mailData.mailType,
                            parentId: data.mailData.parentId,
                            sender
                        };
                        axios.sendNotification(mailData);

                        resolve(data);
                    } else reject(data.message);
                })
                .catch((error) => {
                    console.error('ERROR: ', error);
                    reject('Request time out: Please try again later!');
                });
        });
    },
    REPLY_MAIL: async (
        { getters },
        { sender, receiver, subject, body, attachments, cc, secrets, parentId }
    ) => {
        return new Promise((resolve, reject) => {
            axios
                .replyMail({
                    userWallet: getters.userInfo.address,
                    sender,
                    receiver,
                    cc,
                    subject,
                    body,
                    attachments,
                    secrets,
                    parentId
                })
                .then(({ data }) => {
                    if (!data.error) {
                        const mailData = {
                            recipients: [
                                ...data.mailData.receiver,
                                ...data.mailData.cc
                            ],
                            mailType: data.mailData.mailType,
                            parentId: data.mailData.parentId,
                            sender
                        };
                        axios.sendNotification(mailData);
                        resolve(data);
                    } else reject(data.message);
                })
                .catch((error) => {
                    console.error('ERROR: ', error);
                    reject('Request time out: Please try again later!');
                });
        });
    },
    FORWARD_MAIL: async (
        { getters },
        {
            sender,
            receiver,
            subject,
            body,
            attachments,
            CC,
            secrets,
            parentId,
            previousMails
        }
    ) => {
        return new Promise((resolve, reject) => {
            axios
                .forwardMail({
                    userWallet: getters.userInfo.address,
                    sender,
                    receiver,
                    cc: CC,
                    subject,
                    body,
                    attachments,
                    secrets,
                    parentId,
                    previousMails
                })
                .then(({ data }) => {
                    if (!data.error) {
                        const mailData = {
                            recipients: [
                                ...data.mailData.receiver,
                                ...data.mailData.cc
                            ],
                            mailType: data.mailData.mailType,
                            parentId: data.mailData.parentId,
                            sender
                        };
                        axios.sendNotification(mailData);
                        resolve(data);
                    } else reject(data.message);
                })
                .catch((error) => {
                    console.error('ERROR: ', error);
                    reject('Request time out: Please try again later!');
                });
        });
    },

    GET_REPLY_MAILS: ({ getters }, body) => {
        return new Promise((resolve, reject) => {
            axios
                .getReplyMails(getters.userInfo.emailId, body.id, body.parentId)
                .then(({ data }) => {
                    if (data.error) reject(data.message);
                    else resolve(data.mails);
                })
                .catch((error) => reject(error));
        });
    },
    GET_FWD_MAILS: ({ getters }, previousMails) => {
        return new Promise((resolve, reject) => {
            axios
                .getForwardMails(getters.userInfo.emailId, previousMails)
                .then(({ data }) => {
                    if (data.error) reject(data.message);
                    else resolve(data.mails);
                })
                .catch((error) => reject(error));
        });
    },
    UPDATE_INBOX_PAGE_OPTIONS: ({ commit }, inboxPageOptions) => {
        commit('UPDATE_INBOX_PAGE_OPTIONS', inboxPageOptions);
    },
    UPDATE_DRAFT_PAGE_OPTIONS: ({ commit }, draftPageOptions) => {
        commit('UPDATE_DRAFT_PAGE_OPTIONS', draftPageOptions);
    },
    UPDATE_SENT_PAGE_OPTIONS: ({ commit }, sentPageOptions) => {
        commit('UPDATE_SENT_PAGE_OPTIONS', sentPageOptions);
    },
    UPDATE_ARCHIVE_PAGE_OPTIONS: ({ commit }, archivePageOptions) => {
        commit('UPDATE_ARCHIVE_PAGE_OPTIONS', archivePageOptions);
    }
};

export default { state, mutations, getters, actions };
