import LoginAPI from "@/axios/api/login-api";
import MetamaskAPI from "@/axios/api/metamask";
import ledgermailV2 from "@/plugins/ledgermail-jslib";
import { encrypt } from "@metamask/eth-sig-util";
import { ethers } from "ethers";
import sha256 from "sha256";
import Web3 from "web3";

const state = {
  metaMaskSignupAccessId: "",
  metaMaskSignupMnemonic: "",
  metaMaskWallet: "",
  isMetaMaskSingUpStarted: false,
};

const mutations = {
  STORE_METAMASK_SIGNUP_DETAILS: (state, { accessId, account, mnemonic }) => {
    state.isMetaMaskSingUpStarted = true;
    state.metaMaskSignupAccessId = accessId;
    state.metaMaskSignupMnemonic = mnemonic;
    state.metaMaskWallet = account;
  },
  CLEAR_METAMASK_SINGUP_DETAILS: (state) => {
    state.metaMaskSignupAccessId = "";
    state.metaMaskSignupMnemonic = "";
    state.metaMaskWallet = "";
    state.isMetaMaskSingUpStarted = false;
  },
};

const getters = {
  metaMaskSignupAccessId: (state) => state.metaMaskSignupAccessId,
  metaMaskSignupMnemonic: (state) => state.metaMaskSignupMnemonic,
  metaMaskWallet: (state) => state.metaMaskWallet,
  isMetaMaskSingUpStarted: (state) => state.isMetaMaskSingUpStarted,
};

const actions = {
  METAMASK_GET_SIGNATURE: async (_action, message) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    return signer.signMessage(`0x${sha256(message)}`);
  },
  META_REG_ACCOUNT: () => {
    if (!window.ethereum)
      return { error: true, message: "MetaMask is not installed" };
    return window.ethereum.request({ method: "eth_requestAccounts" });
  },
  GET_META_ACCESS_KEY: async () => {
    const tempMnemonic = ledgermailV2.generateMnemonic();
    const tempHex = await ledgermailV2.getHexFormMnemonic(tempMnemonic);
    const tempExtPublicKey = await ledgermailV2.getXPublicKey(tempHex);
    const tempExtPrivateKey = await ledgermailV2.getXPrivateKey(tempHex);
    const { data } = await LoginAPI.getAccessKey(tempExtPublicKey);
    const accessKey = await ledgermailV2.decrypt(
      JSON.parse(data.accessKey),
      tempExtPrivateKey
    );
    return accessKey;
  },
  META_UPDATE_PASSWORD: async ({ dispatch }, { signature, newPassword }) => {
    const accessKey = await dispatch("GET_META_ACCESS_KEY");

    const details = await ledgermailV2.encrypt(newPassword, accessKey);
    return MetamaskAPI.metaMaskChangeUserPassword(
      signature,
      accessKey,
      details
    );
  },
  CONNECT_WALLET_EXISTING_ACCOUNT: async ({ getters, dispatch }) => {
    try {
      if (getters.userInfo.connectedToWeb3)
        return { error: true, message: "Already connect to Metamask" };

      const account = (await dispatch("META_REG_ACCOUNT"))[0];
      if (getters.userInfo.address.toLowerCase() != account.toLowerCase())
        return {
          error: true,
          message: "Please try again with your LedgerMail wallet",
          showDetails: true,
        };

      const signature = await dispatch(
        "METAMASK_GET_SIGNATURE",
        "LedgerMail_LinkToWeb3"
      );

      const mnemonic = ledgermailV2.ASEDecrypt(
        getters.userInfo.mnemonic,
        getters.userPassword
      );
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.send("eth_requestAccounts");
      const keyB64 = await window.ethereum.request({
        method: "eth_getEncryptionPublicKey",
        params: [account],
      });
      const userPublicKey = Buffer.from(keyB64, "base64");
      const metaEncryptedKey = encrypt({
        publicKey: userPublicKey.toString("base64"),
        data: mnemonic,
        version: "x25519-xsalsa20-poly1305",
      });
      delete metaEncryptedKey.version;

      const { data } = await MetamaskAPI.metaMaskConnectWallet(
        signature,
        metaEncryptedKey
      );
      return { ...data, account };
    } catch (error) {
      console.error("CONNECT_WALLET_EXISTING_ACCOUNT: ", error);
      return {
        error: true,
        message:
          error.message && error.message.length < 50
            ? error.message
            : "Could not connect to wallet",
      };
    }
  },
};

export default { state, mutations, getters, actions };
