import axios from "@/plugins/axios.js";

const state = { searchedMails: [], searchedTotalMails: 0, searchOptions: {} };

const mutations = {
  UPDATE_SEARCH_OPTIONS: (state, data) => {
    state.searchOptions = data;
  },
  UPDATE_SEARCH_MAIL: (state, data) => {
    state.searchedMails = state.searchedMails = data.mails;
    state.searchedTotalMails = data.totalCount[0]
      ? data.totalCount[0].count
      : 0;
  },
};

const getters = {
  getSearchedMails: (state) => state.searchedMails,
  getSearchedTotalMails: (state) => state.searchedTotalMails,
  getSearchOptions: (state) => state.searchOptions,
};

const actions = {
  GET_SEARCHED_MAILS: (
    { commit },
    {
      email,
      limit,
      pageNo,
      type,
      from,
      to,
      hasAttachments,
      key,
      fromDate,
      toDate,
    }
  ) => {

    commit("UPDATE_SEARCH_OPTIONS", {
      email,
      limit,
      pageNo,
      type,
      from,
      to,
      hasAttachments,
      key,
      fromDate,
      toDate,
    });
    return new Promise((resolve, reject) => {
      axios
        .getAdvancedSearchResults({
          email,
          limit,
          pageNo,
          type: type ? type : "All",
          from,
          to,
          hasAttachments,
          key,
          fromDate,
          toDate,
        })
        .then(({ data }) => {
          commit("UPDATE_SEARCH_MAIL", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  UPDATE_SEARCH_OPTIONS: (
    { commit },
    {
      email,
      limit,
      pageNo,
      type,
      from,
      to,
      hasAttachments,
      key,
      fromDate,
      toDate,
    }
  ) => {
    commit("UPDATE_SEARCH_OPTIONS", {
      email,
      limit,
      pageNo,
      type,
      from,
      to,
      hasAttachments,
      key,
      fromDate,
      toDate,
    });
  },
  CLEAR_SEARCH_OPTIONS: ({ commit }) => {
    commit("UPDATE_SEARCH_OPTIONS", {});
  },
};

export default { state, mutations, getters, actions };
