export default {
  path: "/referral",
  component: () => import("../views/authentication/AuthContainer.vue"),
  redirect: "/referral/:code",
  children: [
    {
      path: "/referral/:code",
      component: () => import("../views/authentication/signup/UserDetails.vue"),
      meta: {
        title: "Referral Signup",
        description:
          "I am part of LedgerMail to secure my email platforms in a Decentralized way! LedgerMail provides the highest level of Security, Privacy, Encryption, and Prevention from email attacks. Let's join their mission of protecting the Digital Rights of email users with Free access & 10 $XDC coins as Sign up bonus! ",
      },
      name: "referral-login",
    },
  ],
  meta: {
    noUserAvailable: true,
  },
};
