import Axios from 'axios';

const ipfs = Axios.create({
    baseURL: `https://ledgermail.infura-ipfs.io/ipfs`
});

let baseURL = 'https://' + window.location.host;
let apiPath = '/api';
let NotificationURL = baseURL + apiPath + '/notification';

if (window.location.hostname === 'b2c-dev-mail.ledgermail.io') {
    baseURL = 'https://b2c-dev-mail.ledgermail.io';
}

let sign_up = baseURL + apiPath + '/signup/v1';
let user_ctrl = baseURL + apiPath + '/user-controller/v1';
let mail_filter = baseURL + apiPath + '/filter-mails/v1';
let mail_fetch = baseURL + apiPath + '/get-mails/v1';

if (window.location.hostname === 'localhost') {
    NotificationURL = 'http://localhost:7009/api/notification';
    sign_up = 'http://localhost:7010/api/signup/v1';
    user_ctrl = 'http://localhost:7012/api/user-controller/v1';
    mail_filter = 'http://localhost:7006/api/filter-mails/v1';
    mail_fetch = 'http://localhost:7007/api/get-mails/v1';
}

const getHeaders = () => {
    return {
        authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('auth-token')).token
    };
};

export default {
    // ========= Reward Server ========= //
    async userTransfer(emailId) {
        return await Axios.post(
            `${user_ctrl}/auth/wallet/user-transfer`,
            { emailId },
            { headers: getHeaders() }
        );
    },
    async getReferDetails(emailId, address) {
        return Axios.post(
            `${sign_up}/auth/referral/details`,
            { emailId, address },
            { headers: getHeaders() }
        );
    },

    // ============ update mail ============== //
    async seenMail(email, mailId, type) {
        return await Axios.post(
            `${mail_filter}/auth/read-mail`,
            { email, mailId, type },
            { headers: getHeaders() }
        );
    },
    async pushToArchive(email, mailId) {
        return await Axios.post(
            `${mail_filter}/auth/update-mail/push-archive`,
            { email, mailId },
            { headers: getHeaders() }
        );
    },
    async removeFromArchive(email, mailId) {
        return await Axios.post(
            `${mail_filter}/auth/update-mail/pull-archive`,
            { email, mailId },
            { headers: getHeaders() }
        );
    },

    // ============ fetch Mail ============= //
    async getInbox(email, pageNo = 1, limit = 50, key = '') {
        return await Axios.post(
            `${mail_fetch}/auth/inbox/get-inbox`,
            { email, limit, pageNo, key },
            { headers: getHeaders() }
        );
    },
    async getSent(email, pageNo = 1, limit = 50, key = '') {
        return await Axios.post(
            `${mail_fetch}/auth/sent/get-sent`,
            { email, pageNo, limit, key },
            { headers: getHeaders() }
        );
    },
    async getArchive(email, pageNo = 1, limit = 50, key = '') {
        return await Axios.post(
            `${mail_fetch}/auth/archive/get-archive`,
            { email, limit, pageNo, key },
            { headers: getHeaders() }
        );
    },
    async getDraft(pageNo = 1, limit = 50, key = '') {
        return await Axios.post(
            `${mail_fetch}/auth/draft/get-draft`,
            { limit, pageNo, key },
            { headers: getHeaders() }
        );
    },

    // ============ filter Mail ============= //
    async getReplyMails(email, id, parentId) {
        return await Axios.post(
            `${mail_filter}/auth/mails/get-child-mails`,
            { email, id, parentId },
            { headers: getHeaders() }
        );
    },
    async getForwardMails(email, previousMails) {
        return await Axios.post(
            `${mail_filter}/auth/mails/get-forward-mail`,
            { email, previousMails },
            { headers: getHeaders() }
        );
    },
    async getSingleInboxMail(email, parentId) {
        return await Axios.post(
            `${mail_fetch}/auth/sent/single-inbox-mail`,
            { email, parentId },
            { headers: getHeaders() }
        );
    },

    // ========= Notification Server ========= //
    notificationURL: NotificationURL,
    async sendNotification({ recipients, mailType, parentId, sender }) {
        return await Axios.post(
            `${NotificationURL}/chat/new-mail`,
            { recipients, mailType, parentId, sender },
            { headers: getHeaders() }
        );
    },

    // ========= User controller ========= //
    async getBalance(email) {
        return await Axios.post(
            `${user_ctrl}/auth/user-details/balance`,
            { email },
            { headers: getHeaders() }
        );
    },
    async getPublicKeys(emailIds) {
        return await Axios.post(
            `${user_ctrl}/auth/user-details/public-key`,
            { emailIds },
            { headers: getHeaders() }
        );
    },

    // =========== advanced search ============== //
    async getAdvancedSearchResults({
        email,
        limit,
        pageNo,
        type,
        from,
        to,
        hasAttachments,
        key,
        fromDate,
        toDate
    }) {
        return await Axios.post(
            `${mail_filter}/auth/search`,
            {
                email,
                limit,
                pageNo,
                type,
                from,
                to,
                hasAttachments,
                key,
                fromDate,
                toDate
            },
            { headers: getHeaders() }
        );
    },

    // ========= Files ========= //
    async getFile(hash) {
        return await ipfs.get(`/${hash}`);
    }
};
