import axios from "@/plugins/axios.js";

const state = {
  archiveLoading: false,
};

const mutations = {
  AM_LOADING: (state) => {
    state.archiveLoading = true;
  },

  AM_COMPLETE: (state) => {
    state.archiveLoading = false;
  },
};

const getters = {
  archiveLoading: (state) => state.archiveLoading,
};

const actions = {
  MAIL_TO_ARCHIVE: async ({ getters, commit }, mail) => {
    commit("AM_LOADING");
    let index = -1;

    for (let i = 0; i < getters.getArchive.length; i++) {
      if (getters.getArchive[i].createdAt <= mail.createdAt) {
        index = i;
        break;
      }
    }

    if (index != -1) commit("PUSH_ARCHIVE", { index, mail });
    else commit("PUSH_ARCHIVE", { index: getters.getArchive.length, mail });

    setTimeout(() => {
      axios.pushToArchive(getters.userInfo.emailId, mail._id);
      commit("AM_COMPLETE");
    }, 10);
  },

  REMOVE_FROM_ARCHIVE: async ({ getters, commit }, mail) => {
    let index = 0;

    if (getters.userInfo.emailId == mail.sender) {
      for (let i = 0; i < getters.getSent.length; i++) {
        index = i;
        if (getters.getSent[i].createdAt <= mail.createdAt) break;
      }

      commit("PUSH_SENT", { index, mail });
    } else {
      for (let i = 0; i < getters.getInbox.length; i++) {
        index = i;
        if (getters.getInbox[i].createdAt <= mail.createdAt) break;
      }

      commit("PUSH_INBOX", { index, mail });
    }

    axios.removeFromArchive(getters.userInfo.emailId, mail._id);
  },

  GET_ARCHIVE_MAILS: (
    { getters, commit, dispatch },
    { pageNo, limit, key, refreshData }
  ) => {
   
    if (
      getters.getArchive.length > (pageNo - 1) * limit &&
      !key &&
      !refreshData
    )
      return;
    return new Promise((resolve, reject) => {
      axios
        .getArchive(getters.userInfo.emailId, pageNo, limit, key ? key : "")
        .then(({ data }) => {
          commit("UPDATE_ARCHIVE", { data });
          if (!refreshData) commit("UPDATE_ARCHIVE", { data });
          else commit("INIT_ARCHIVE_MAIL", { data: data });
          resolve();
        })
        .catch((error) => {
          commit("INIT_MAIL_ERROR");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          )
            dispatch("ALERT_ERROR", error.response.data.error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
