import AdminAPI from "@/axios/api/admin-api.js";
import ledgermailV2 from '@/plugins/ledgermail-jslib';

const state = {
  enterpriseNames: [],
  enterpriseUsers: [],
  autoCompleteCompose: []
}

const mutations = {
  SET_ENTERPRISE_NAME_AND_USERS: (state, { enterpriseNames, users }) => {
    state.enterpriseNames = enterpriseNames
    state.enterpriseUsers = users
  },
  UPDATE_NAME_OF_ENT_USER: (state, { index, userData }) => {
    state.enterpriseUsers[index].firstName = userData.firstName;
    state.enterpriseUsers[index].lastName = userData.lastName;
  },
  UPDATE_DOMAIN_OF_ENT_USER: (state, { index, domainName }) => {
    if (state.enterpriseUsers[index].domainName.length) return

    state.enterpriseUsers[index].domainName.push(domainName);
  },
  STORE_AUTO_COMPLETE_DATA: (state, data) => {
    state.autoCompleteCompose = data
  }
}

const getters = {
  enterpriseNames: (state) => state.enterpriseNames,
  enterpriseUsers: (state) => state.enterpriseUsers,
  autoCompleteCompose: (state) => state.autoCompleteCompose
}

const actions = {
  ENTERPRISE_INIT: async ({ getters, commit }) => {
    if (!getters.userInfo.enterprise || !getters.userInfo.enterprise.length) return
    if (getters.userInfo.enterprise != "FDPPI") return


    const hex = await ledgermailV2.getHexFormMnemonic(ledgermailV2.generateMnemonic());
    const publicKey = await ledgermailV2.getXPublicKey(hex);
    const xPrivateKey = await ledgermailV2.getXPrivateKey(hex);

    try {
      const { data } = await AdminAPI.getAutoFilContent({ accessKey: publicKey, domainName: getters.userInfo.enterprise })

      if (data.error)
        console.error("ENT_INIT [DATA_ERROR]:", data.error)

      const allEnterpriseUsers = (await ledgermailV2.decrypt(JSON.parse(data.allEnterpriseUsers), xPrivateKey)).split(',')

      let index = -1;
      index = allEnterpriseUsers.indexOf(getters.userInfo.emailId)

      if (index > -1)
        allEnterpriseUsers.splice(index, 1);

      index = -1
      if (getters.userInfo.domainName.length) {
        index = allEnterpriseUsers.indexOf(getters.userInfo.domainName[0])
        if (index > -1)
          allEnterpriseUsers.splice(index, 1);
      }

      commit("STORE_AUTO_COMPLETE_DATA", allEnterpriseUsers)
      return allEnterpriseUsers

    } catch (error) {
      console.error("ENT_INIT [ERROR]:", error)
    }
  }
}

export default { state, mutations, getters, actions };
