import axiosData from "@/axios/axios.js";
const { axios, url, getHeaders } = axiosData;

export default {
  getAllUsers() {
    return axios.get(`${url.enterprise}/auth/admins/list-user`,
      { headers: getHeaders() }
    );
  },
  associateDomainName({ domainName, email }) {
    return axios.post(`${url.enterprise}/auth/admins/associate-email/${email}`, { domainName },
      { headers: getHeaders() }
    );
  },
  getAutoFilContent({ domainName, accessKey }) {
    return axios.post(`${url.enterprise}/auth/enterprise-users/${domainName}`, { accessKey },
      { headers: getHeaders() }
    );
  },
  checkUsers(email) {
    return axios.get(`${url.enterprise}/auth/admins/check-user/${email}`,
      { headers: getHeaders() }
    );
  },
  acceptExistingUserToEnterprise(token) {
    return axios.get(`${url.enterprise}/accept-user?token=${token}`,
    );
  },
  addUsersToEnterPrise(usersDetails) {
    return axios.post(`${url.enterprise}/auth/admins/add-users`, { usersDetails },
      { headers: getHeaders() }
    );
  },

  updateUserDetails(data) {
    return axios.post(`${url.enterprise}/auth/admins/update-users/details`, { ...data },
      { headers: getHeaders() }
    );
  },
  updateUserPassword(data) {
    return axios.post(`${url.enterprise}/auth/admins/update-users/password`, { ...data },
      { headers: getHeaders() }
    );
  }
}