import Axios from 'axios';

let baseURL = 'https://' + window.location.host;
let apiPath = '/api';
let enterpriseLink = 'https://enterprise.ledgermail.io';
let enterpriseSiteLink = 'enterprise.ledgermail.io';
let unstoppableLink = 'https://uauth.ledgerfi.io';

let paymentAddress = '0x5a86F68346cAdd7987cF3a4bc0C493A42D0E5f6c';
let chainId = '0x32';
let chainIdDec = '50';
let chainUrl = 'https://rpc.xinfin.network';
let chainName = 'XinFin Network Mainnet';

// from: "xdc8e2296f20847f0105237150b34a6dce831669b8f",
// to: "0x78902AcD8448B5930c88432128D1c588036F5cBF",
// VUE_APP_HCAPTCHA_SK
// VUE_APP_RECAP_SIT_V2

if (window.location.hostname === 'b2c-dev-mail.ledgermail.io') {
    baseURL = 'https://b2c-dev-mail.ledgermail.io';
    enterpriseLink = 'https://b2c-dev-enterprise.ledgermail.io';
    enterpriseSiteLink = 'b2c-dev-enterprise.ledgermail.io';
}

let sign_up = baseURL + apiPath + '/signup/v1';
let user_ctrl = baseURL + apiPath + '/user-controller/v1';
let user_ctrl_v2 = baseURL + apiPath + '/user-controller/v2';
let mail_filter = baseURL + apiPath + '/filter-mails/v1';
let new_compose = baseURL + apiPath + '/compose/v1';
let enterprise = baseURL + apiPath + '/enterprise/v1';

if (window.location.hostname === 'localhost') {
    enterpriseLink = 'http://localhost:3041';
    enterpriseSiteLink = 'localhost:3041';

    sign_up = 'http://localhost:7010/api/signup/v1';
    user_ctrl = 'http://localhost:7012/api/user-controller/v1';
    user_ctrl_v2 = 'http://localhost:7012/api/user-controller/v2';
    mail_filter = 'http://localhost:7006/api/filter-mails/v1';
    new_compose = 'http://localhost:7004/api/compose/v1';
    enterprise = 'http://localhost:7005/api/enterprise/v1';
}

if (
    window.location.hostname === 'b2c-dev-mail.ledgermail.io' ||
    window.location.hostname === 'localhost'
) {
    paymentAddress = '0x78902AcD8448B5930c88432128D1c588036F5cBF';
    chainId = '0x33';
    chainIdDec = '52';
    chainUrl = 'https://rpc.apothem.network';
    chainName = 'Apothem XinFin Network';
}

// const ipfs = Axios.create({
//     baseURL: `${process.env.VUE_APP_IPFS_LINK}/ipfs`
// });

const getHeaders = () => {
    const { token } = JSON.parse(localStorage.getItem('auth-token'));
    return { authorization: 'Bearer ' + token };
};

export default {
    axios: Axios,
    // ipfs,
    unstoppableLink,
    wallet: {
        paymentAddress,
        chainId,
        chainIdDec,
        chainUrl,
        chainName
    },
    url: {
        apiPath,
        sign_up,
        user_ctrl,
        mail_filter,
        new_compose,
        enterprise,
        user_ctrl_v2,
        enterpriseLink,
        enterpriseSiteLink
    },
    getHeaders
};
