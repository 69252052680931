const HDKey = require("hdkey");
const { hdkey } = require("ethereumjs-wallet");
const crypto = require("crypto");
const eccrypto = require("eccrypto");
const bip39 = require("bip39");
const CryptoJS = require("crypto-js");

module.exports.ASEEncrypt = function (message, secret) {
  return CryptoJS.AES.encrypt(message, secret).toString();
};
module.exports.ASEDecrypt = function (cipherText, secret) {
  var bytes = CryptoJS.AES.decrypt(cipherText, secret);
  return bytes.toString(CryptoJS.enc.Utf8);
};

module.exports.getWalletPrivateKey = (mnemonic) => {
  const seedOnServer = bip39.mnemonicToSeedSync(mnemonic);
  const addrNodeServer = hdkey
    .fromMasterSeed(seedOnServer)
    .derivePath("m/44'/60'/0'/0/0");

  return addrNodeServer._hdkey._privateKey.toString("hex");
};

/**
 * generates mnemonic phrase of 12 words
 * @returns {string} bip39 passphrase
 */
module.exports.generateMnemonic = function () {
  return bip39.generateMnemonic();
};

/**
 * @param {string} mnemonic mnemonic phrase of 12 words
 * @returns {boolean} true or false
 */
module.exports.validateMnemonic = (mnemonic) => {
  return bip39.validateMnemonic(mnemonic);
};

/**
 * @param {string} mnemonic mnemonic phrase of 12 words
 * @returns {string} hex value of the mnemonic
 */
module.exports.getHexFormMnemonic = (mnemonic) => {
  return bip39.mnemonicToSeedSync(mnemonic).toString("hex");
};

/**
 * generates public key from passphrase
 * @param {string} passphrase master seed words
 * @returns {string} hex string of public key
 */
module.exports.getHexPublicKeyFromPhrase = async (passphrase) => {
  const seed = bip39.mnemonicToSeedSync(passphrase);
  return HDKey.fromMasterSeed(seed).publicKey.toString("hex");
};

/**
 * generates private key from passphrase
 * @param {string} passphrase master seed words
 * @returns {buffer} private key
 */
module.exports.getPrivateKeyFromPhrase = async (passphrase) => {
  const seed = bip39.mnemonicToSeedSync(passphrase);
  return HDKey.fromMasterSeed(seed).privateKey;
};

/**
 * generates key pairs from extended key
 * @param {string} xKey hex string generated from master seed words
 * @returns {object | buffer} keys
 */
module.exports.getKeyFromXKey = (xKey) => {
  return HDKey.fromExtendedKey(xKey);
};

/**
 * returns extended public key from master seed words
 * @param {string} hexSeedWords hex string generated from master seed words
 */
module.exports.getXPublicKey = (hexSeedWords) => {
  return HDKey.fromMasterSeed(hexSeedWords).publicExtendedKey;
};

/**
 * generates extended private key from master seed words
 * @param {string} hexSeedWords hex string generated from master seed words
 */
module.exports.getXPrivateKey = (hexSeedWords) => {
  return HDKey.fromMasterSeed(hexSeedWords).privateExtendedKey;
};

/**
 * generates signature for the transaction
 * @param {Buffer} txBody transaction data buffer
 * @param {string} secret extended private key
 */
module.exports.verifyTransaction = async (txBody, secret) => {
  const hash = crypto.createHash("sha256").update(txBody).digest();
  return (await eccrypto.sign(secret, hash)).toString("hex");
};
/**
 * Encrypts message using public key
 * @param {string} xPublicKey extended public key
 * @param {string | encryptionPayload} payload
 * @returns {string} encrypted message
 */
module.exports.encrypt = (payload, xPublicKey) => {
  const publicKey = this.getKeyFromXKey(xPublicKey).publicKey;

  return new Promise((resolve, reject) => {
    eccrypto
      .encrypt(publicKey, Buffer.from(payload))
      .then((encrypted) => resolve(JSON.stringify(encrypted)))
      .catch((err) => reject(err));
  });
};
/**
 * Decrypts the message using private key
 * @param {string} xPrivateKey extended private key
 * @param {object | decryptionPayload} payload
 * @returns {string} decrypted message
 */
module.exports.decrypt = (payload, xPrivateKey) => {
  const privateKey = this.getKeyFromXKey(xPrivateKey).privateKey;
  const chipper = {
    iv: Buffer.from(payload.iv.data),
    ephemPublicKey: Buffer.from(payload.ephemPublicKey.data),
    ciphertext: Buffer.from(payload.ciphertext.data),
    mac: Buffer.from(payload.mac.data),
  };

  return new Promise((resolve, reject) => {
    eccrypto
      .decrypt(privateKey, chipper)
      .then((plaintext) => resolve(plaintext.toString()))
      .catch((err) => reject(err));
  });
};
