<template>
    <v-app>
        <alerts></alerts>
        <router-view />
    </v-app>
</template>

<script>
import alerts from "./views/Alerts";
export default {
    created() {
        console.log("LedgerMail v2.2");
    },
    data: () => ({}),
    components: {
        alerts,
    },
    name: "App",
};
</script>

<style lang="scss">
html {
    overflow: auto;
}

.v-item-group a {
    text-decoration: none;
}

.button-background {
    background: linear-gradient(to right, #1388e4 30%, #005387 100%);
}

.max-text {
    width: 250px;
    max-width: 100%;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.tr:hover {
    .td {
        transition: all 0.15s ease-in-out;
    }

    td:first-child {
        border-left: 3px solid var(--v-primary-base) !important;
    }

    cursor: pointer;
}

.read-mail {
    font-weight: 400 !important;

    .td {
        border-left: none !important;
    }
}

.list-mail-tr:hover {
    cursor: pointer;
}

.pt {
    cursor: pointer;
}
</style>
