import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import archiveModule from "./modules/archive-module";
import authModule from "./modules/auth-module";
import cryptoModule from "./modules/crypto-module";
import globalModule from "./modules/global-module";
import mailModule from "./modules/mail-module";
import referModule from "./modules/refer-module";
import seenModule from "./modules/seen-module";
import socketModule from "./modules/socket-module";
import advanceSearchModule from "./new-modules/advance-search-module";
import alert from "./new-modules/alert-module";
import compose from './new-modules/compose-module';
import global from "./new-modules/global-module";
import login from "./new-modules/login-module";
import pending from './new-modules/pending-module';
import signup from "./new-modules/signup-module";
import user from "./new-modules/user-module";
import userProfileModule from "./new-modules/user-profile-module";

import adminModule from "./new-modules/admin-module";
import metaMaskModule from "./new-modules/metamask-module";

export default new Vuex.Store({
  modules: {
    authModule,
    globalModule,
    mailModule,
    referModule,
    cryptoModule,
    socketModule,
    archiveModule,
    seenModule,
    advanceSearchModule,
    userProfileModule,
    alert,
    global,
    login,
    signup,
    user,
    compose,
    pending,

    adminModule,
    metaMaskModule
  },
});
