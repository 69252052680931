import axios from "@/plugins/axios.js";

const state = {};

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading';
  },
  AUTH_SUCCESS: (state, { xKey, user }) => {
    state.status = 'success';
    state.token = xKey;
    state.user = user;
  },
  AUTH_SET_BALANCE: (state, value) => {
    state.balance = value;
  },
  AUTH_ERROR: (state) => {
    state.status = 'error';
    state.token = '';
  },
  AUTH_PENDING: (state) => {
    state.status = 'pending';
  },
};

const getters = {};

const actions = {
  TRANSFER_TOKEN: async ({ commit, getters }) => {
    commit('AUTH_REQUEST');

    return new Promise((resolve, reject) => {
      axios
        .userTransfer(getters.userInfo.emailId)
        .then(({ data }) => {
          commit('AUTH_PENDING');
          if (data.error) reject(data.message);
          else resolve(data);
        })
        .catch((error) => {
          console.error('[Transfer token] ERROR: ', error);
          commit('AUTH_PENDING');
          reject('Request time out: Please try again later!');
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
