import axiosData from "@/axios/axios.js";
const { axios, url, getHeaders } = axiosData;

export default {
  getAccessKey(publicKey) {
    return axios.post(
      `${url.user_ctrl}/get-access-key`,
      { publicKey },
    );
  },
  refreshToken(emailId, publicKey) {
    return axios.post(
      `${url.user_ctrl}/token`,
      { emailId, publicKey },
      { headers: getHeaders() }
    );
  },
  refreshTokenWithPwd({ emailId, publicKey, password, accessKey }) {
    return axios.post(
      `${url.user_ctrl_v2}/token/refresh`,
      { emailId, publicKey, password, accessKey },
      { headers: getHeaders() }
    );
  },
  login({ emailId, password, publicKey, accessKey }) {
    return axios.post(`${url.user_ctrl_v2}/login`, {
      emailId,
      password,
      publicKey,
      accessKey
    });
  },
  sendPasswordResetToken({ email, publicKey, authKey }) {
    return axios.post(
      `${url.user_ctrl}/password/forgot`,
      { email, publicKey },
      {
        headers: { authentication: authKey },
      }
    );
  },
  changePassword({ email, token, password }) {
    return axios.post(`${url.user_ctrl}/password/change`, {
      email,
      token,
      password,
    });
  },
};
