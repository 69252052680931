export default {
  path: "/mail",
  component: () => import("../views/mail/Dashboard.vue"),
  redirect: "/mail/inbox",
  children: [
    {
      path: "/mail/inbox",
      component: () => import("../views/mail/fields/Inbox.vue"),
      name: "inbox",
      meta: {
        title: "Inbox | LedgerMail",
      },
    },
    {
      path: "/mail/sent",
      component: () => import("../views/mail/fields/Sent.vue"),
      name: "sent",
      meta: {
        title: "Sent | LedgerMail",
      },
    },
    {
      path: "/mail/archive",
      component: () => import("../views/mail/fields/Archive.vue"),
      name: "archive",
      meta: {
        title: "Archive | LedgerMail",
      },
    },
    {
      path: "/mail/draft",
      component: () => import("../views/mail/fields/Draft.vue"),
      name: "draft",
      meta: {
        title: "Draft | LedgerMail",
      },
    },
    {
      path: "/mail/search-result",
      component: () => import("../views/mail/fields/AdvanceSearchResult.vue"),
      name: "searchResult",
      meta: {
        title: "search Result | LedgerMail",
      },
    },
    {
      path: "/mail/inbox/:id",
      component: () =>
        import("../views/mail/components/mail-view/mail-view.vue"),
      name: "inbox-mail",
      meta: {
        title: "Inbox | LedgerMail",
      },
    },
    {
      path: "/mail/sent/:id",
      component: () =>
        import("../views/mail/components/mail-view/mail-view.vue"),
      name: "sent-mail",
      meta: {
        title: "Sent | LedgerMail",
      },
    },
    {
      path: "/mail/archive/:id",
      component: () =>
        import("../views/mail/components/mail-view/mail-view.vue"),
      name: "archive-mail",
      meta: {
        title: "Archive | LedgerMail",
      },
    },
    {
      path: "/mail/draft/:id",
      component: () =>
        import(
          "../views/mail/components/mail-view/compose-components/viewDraft.vue"
        ),
      name: "draft-mail",
      meta: {
        title: "Draft | LedgerMail",
      },
    },
    {
      path: "/mail/search-result/:id",
      component: () =>
        import("../views/mail/components/mail-view/mail-view.vue"),
      name: "searchResult-mail",
      meta: {
        title: "search result | LedgerMail",
      },
    },
    {
      path: "/mail/pending",
      component: () => import("../views/mail/fields/Pending.vue"),
      name: "pending",
      meta: {
        title: "Pending | LedgerMail",
      },
    },
    {
      path: "/mail/pending/:id",
      component: () =>
        import("../views/mail/components/mail-view/pending-view.vue"),
      name: "pending-mail",
      meta: {
        title: "Pending | LedgerMail",
      },
    },
  ],
  meta: { requiresAuth: true },
};
