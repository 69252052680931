import UserProfileAPI from "@/axios/api/user-profile-api";

const state = {
  image: null,
  userData: {},
  invitedEmailId: null,
  isUserHaveImage: false,
};

const mutations = {
  SET_USER_DATA: (state, data) => {
    state.userData = data;
  },
  INVITED_USER: (state, emailId) => {
    state.invitedEmailId = emailId;
  },
  SET_PROFILE_IMAGE: (state, link) => {
    state.image = link;
  },
  SET_USER_IMG_FLAG: (state, value) => {
    state.isUserHaveImage = value;
  },
};

const actions = {
  FETCH_USER_DATA: ({ commit }, { data }) => {
    UserProfileAPI.fetchUserData(data).then(({ data }) => {
      commit("SET_USER_DATA", data);
    });
  },
  UPDATE_USER_DATA: ({ commit, getters }, data) => {
    return new Promise((resolve, reject) => {
      UserProfileAPI.updateUserData(data)
        .then((result) => {
          if (result.data.error) {
            reject(result.data.message);
          } else {
            let userInfo = getters.userInfo;
            userInfo.firstName = data.firstName;
            userInfo.lastName = data.lastName;
            userInfo.DoB = data.DoB;
            localStorage.setItem("user-info", JSON.stringify(userInfo));

            commit("SET_USER_DATA", data);
            resolve(result.data);
          }
        })
        .catch((error) => {
          console.error("UPDATE_USER_DATA:", error);
          reject(error.message ? error.message : "Update error");
        });
    });
  },
  SET_USER_PROFILE_IMAGE: ({ commit }, image) => {
    commit("SET_PROFILE_IMAGE", image);
  },
  UPDATE_USER_PROFILE_IMAGE: ({ commit }, formData) => {
    commit("SET_PROFILE_IMAGE", null);
    return new Promise((resolve, reject) => {
      UserProfileAPI.updateProfileImage(formData).then((result) => {
        if (result.data.error) {
          reject(result.data.message);
        } else {
          resolve(result.data.message);
        }
      });
    });
  },
  CHECK_PROFILE_IMAGE_EXISTS: async ({ commit }, email) => {
    try {
      const { data } = await UserProfileAPI.fetchProfileImage(email);
      if (data && data.error) {
        commit("SET_USER_IMG_FLAG", false);
        return false;
      }

      commit("SET_USER_IMG_FLAG", true);
      return true;
    } catch (error) {
      commit("SET_USER_IMG_FLAG", false);
      return false;
    }
  },
  INVITE_USER: ({ commit }, emailId) => {
    return new Promise((resolve, reject) => {
      UserProfileAPI.inviteUser(emailId).then((result) => {
        commit("INVITED_USER", emailId);
        if (result.data.error) reject(result.data.message);
        else resolve(result.data.message);
      });
    });
  },
};

const getters = {
  getUserData: (state) => state.userData,
  getCloudProfileImage: (state) => state.image,
  isUserHaveImage: (state) => state.isUserHaveImage,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
